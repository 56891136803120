<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div
    class="flex h-screen w-full bg-img vx-row no-gutter justify-center"
    id="page-login"
  >
    <div class="container">
      <div class="vx-col lg:w-1/2 sm:m-0">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col w-full d-theme-dark-bg border rounded">
                <div class="login-tabs-container border rounded">
                  <div class="vx-card__title">
                    <h1 align="center">Forgot Password</h1>
                  </div>
                  <div>
                    <vs-alert
                      id="alert"
                      v-if="this.emailError === false"
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="success"
                      >{{ message }}</vs-alert
                    >
                    <vs-alert
                      v-else
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="danger"
                      >{{ message }}</vs-alert
                    >
                    <br />
                    <div class="input-wrap">
                      <vs-input
                        v-validate="'required|email|min:3'"
                        data-vv-validate-on="blur"
                        name="email"
                        label-placeholder="Email"
                        v-model="email"
                        class="w-full mt-10"
                      />
                      <span class="text-danger text-sm">{{
                        errors.first("email")
                      }}</span>
                    </div>
                    <div class="input-wrap">
                      <vs-button
                        class="flex flex-wrap justify-content-center mt-10 mb-6"
                        :disabled="!validateForm || disableButton"
                        @click="resetPassword"
                        >Recover Password</vs-button
                      >
                      <vs-button
                        type="border"
                        @click="$router.go(-1)"
                        class="flex flex-wrap justify-content-center"
                        >Back To Login</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
  <!-- <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title ">
                                    <h4 class="mb-4">Recover your password</h4>
                                    <p>Please enter your email address and we'll send you instructions on how to reset
                                        your password.</p>
                                </div>
                                 <vs-alert
                                  id="alert"
                                  v-if="this.emailError === false"
                                  :active.sync="showDismissibleAlert"
                                  closable
                                  icon-pack="feather"
                                  close-icon="icon-x"
                                  color="success"
                                >{{message}}</vs-alert>
                                  <vs-alert
                                    v-else
                                    :active.sync="showDismissibleAlert"
                                    closable
                                    icon-pack="feather"
                                    close-icon="icon-x"
                                    color="danger"
                                  >{{message}}</vs-alert>
                                <br />
                                <vs-input
                                    :danger="errors.first('email')"
                                    :success="!errors.first('email') && email!=''"
                                    val-icon-success="done"
                                    val-icon-danger="error"
                                    v-validate="'required|email|min:3'"
                                    data-vv-validate-on="blur"
                                    name="email"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="Email"
                                    v-model="email"
                                    class="w-full"/>
                          <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                               <br/>
                                <vs-button type="border" :to="{ name: 'admin-login'}" class="px-4 w-full md:w-auto">Back To Login
                                </vs-button>
                                <vs-button class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                                           :disabled="!validateForm" @click="resetPassword">Recover Password
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
  </div>-->
</template>

<script>
export default {
  data() {
    return {
      email: "",
      showDismissibleAlert: false,
      message: "",
      emailError: false,
      disableButton: false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "";
    }
  },
  methods: {
    resetPassword() {
      let email = this.email;

      this.$validator.validate().then(valid => {
        if (valid) {
          this.$vs.loading();
          this.$store
            .dispatch("auth/forgotPWD", email)
            .then(response => {
              console.log(response);
              this.emailError = false;
              this.showDismissibleAlert = true;
              this.message = response.data.message;
              this.$vs.loading.close();
              this.disableButton = true;
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.emailError = true;
                this.showDismissibleAlert = true;
                this.message = error.response.data.message;
              } else {
                this.emailError = true;
                this.showDismissibleAlert = true;
                this.message = error.message;
              }
              this.$vs.loading.close();
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
#page-login {
  background: #ffffff;
  .vx-col {
    .w-full {
      width: 100% !important;
    }
    margin: 0 auto !important;
    width: 100%;
    @media (min-width: 992px) {
      width: 80%;
    }
    @media (min-width: 1200px) {
      width: 67% !important;
    }
    @media (min-width: 1480px) {
      width: 75% !important;
    }
    @media (min-width: 1640px) {
      width: 68% !important;
    }
  }

  .vx-card {
    border-radius: 8px !important;
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.11);
    margin: 60px 0;
    overflow: hidden;
    @media (max-width: 767px) {
      box-shadow: none;
      margin: 30px 0;
      border-radius: 0;
    }
    @media (min-width: 1200px) {
      margin: 80px 0;
    }
    @media (min-width: 1640px) {
      margin: 90px 0;
    }
  }

  .vs-button {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    padding: 15px !important;
    width: 100%;
  }
}

.login-tabs-container {
  padding: 20px;
  width: 100%;
  @media (min-width: 992px) {
    width: 80%;
    padding: 0;
  }
  @media (min-width: 1024px) {
    margin: 60px auto !important;
    width: 70%;
  }
  @media (min-width: 1200px) {
    margin: 70px auto !important;
    width: 67%;
  }
  @media (min-width: 1640px) {
    width: 56%;
    margin: 94px auto !important;
  }
  h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
    @media (min-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (min-width: 1640px) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 26px;
    }
  }
}
</style>
